import vrt from '../assets/img/game_SI/15/d15.jpg'
import kuhinja from '../assets/img/game_SI/15/k15.jpg'
import outItem1 from '../assets/img/game_SI/14/vrt/1.png'
import outItem2 from '../assets/img/game_SI/14/vrt/2.png'
import outItem3 from '../assets/img/game_SI/14/vrt/3.png'
import outItem4 from '../assets/img/game_SI/14/vrt/4.png'
import outItem5 from '../assets/img/game_SI/14/vrt/5.png'
import p1 from '../assets/img/game_SI/15/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game_SI/15/kuhinja/5907707051296-Pisano-pismo-76-g.png'
import k2 from '../assets/img/game_SI/15/kuhinja/prhki.png'
import k3 from '../assets/img/game_SI/15/kuhinja/Modlice Zečja obitelj-6.png'
import k4 from '../assets/img/game_SI/15/kuhinja/Modlice za kekse u obliku jajeta s motivima zeca i tulipana.png'
import k5 from '../assets/img/game_SI/15/kuhinja/recept7.png'

const day_15 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-87deg", pitch: "-42.1deg" },
                size: { width: 20, height: 25 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "64.2deg", pitch: "-28.5deg" },
                size: { width: 20, height: 24 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-115.3deg", pitch: "-27.4deg" },
                size: { width: 20, height: 26 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "139.8deg", pitch: "-36.8deg" },
                size: { width: 26, height: 30 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-44.4deg", pitch: "-20.2deg" },
                size: { width: 20, height: 24 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-11deg", pitch: "-13.5deg" },
                size: { width: 45, height: 55 },
                product_title: 'Pisano pismo 76g',
                product_description: 'S pomočjo sladkorne pisave lahko slaščice okrasite na najrazličnejše načine, samo pustite domišljiji prosto pot! Napišite sporočilo ali pa kaj narišite. V zavitku so 4 tubice z zeleno, rdečo, rumeno in belo barvo.',
                product_image: k1,
                product_url: 'https://oetker-shop.si/izdelek/pisano-pismo-76g/'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "15deg", pitch: "-19.5deg" },
                size: { width: 70, height: 100 },
                product_title: 'Krhki piškoti 500g',
                product_description: 'S pomočjo receptov iz babičine kuhinje, ki so priloženi v pakiranju, lahko tradicionalne kekse iz krhkega testa hitro in preprosto pripravite na 5 različnih načinov.',
                product_image: k2,
                product_url: 'https://oetker-shop.si/izdelek/krhki-piskoti-500g/'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-195deg", pitch: "-54.6deg" },
                size: { width: 190, height: 195 },
                product_title: 'Modelčki za piškote ‘Zajčja družina’ 3 kos',
                product_description: 'To veliko noč boste s pomočjo modelčkov “Zajčja družina” naredili najbolj ustvarjalne piškote za družino in prijatelje!',
                product_image: k3,
                product_url: 'https://oetker-shop.si/izdelek/modelcki-za-piskote-zajcja-druzina/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-106.5deg", pitch: "-32.4deg" },
                size: { width: 180, height: 180 },
                product_title: 'Modelčki za piškote v obliki jajčka z motivi zajčka in tulipana',
                product_description: 'Vnesite delček spomladi v svoj dom! Letos za veliko noč naredite, s pomočjo modelčkov v obliki jajčka z motivom zajčka in tulipana, najbolj domiselne piškote za družino in prijatelje.',
                product_image: k4,
                product_url: 'https://oetker-shop.si/izdelek/modelcki-za-piskote-v-obliki-jajcka-z-motivi-zajcka-in-tulipana/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-38.1deg", pitch: "-35deg" },
                size: { width: 140, height: 140 },
                product_title: 'Kolački z ovčkami',
                product_description: 'Če ste ljubitelj čokolade, naredite te čokoladne muffine s stepeno smetano in koščki meringe v obliki ovčk',
                product_image: k5,
                product_url: 'https://www.oetker.si/si-si/recept/r/kolacki-z-ovckami'
            },
        ]
    },
]

export default day_15